<template>
    <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"  v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item label="筛选日期：">
        <el-date-picker v-model="date"  type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" :editable="false" :clearable="false"/>
      </el-form-item>
      <el-form-item>
          <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="状态" v-model="listQuery.condition.status">
            <el-option v-for="item in statusOptions" :key="item.name" :label="item.type" :value="item.name">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="制单人或送办人" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">查询</el-button>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
      </el-row>
    </template>
    <template #columns>
      <el-table-column min-width="150px" label="单据类型" align="center">
          <span>标签申请单</span>
      </el-table-column>
      <el-table-column min-width="150px" label="标签申请人" align="center" property="creatorName">
        <template  #default="scope">
          <span>{{scope.row.creatorName}}</span>
        </template>
      </el-table-column>
       <el-table-column min-width="150px" label="状态" align="center" property="status">
        <template  #default="scope">
          <el-tag :type="showFilter(scope.row)" >{{scope.row.status&& scope.row.status.type}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="制单时间" align="center" property="createTime">
        <template  #default="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="更新时间" align="center" property="updateTime">
        <template  #default="scope">
          <span>{{scope.row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" align="center" label="制单人" property="creatorName">
        <template  #default="scope">
          <span>{{scope.row.creatorName}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" align="center" label="申请标签（套）" property="labelCount">
        <template  #default="scope">
          <span>{{scope.row.labelCount}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300px" class-name="fixed-width">
        <template  #default="scope">
          <el-button type="primary" size="mini" @click="handleInfo(scope.row)" v-waves>查看</el-button>
          <el-button type="danger" size="mini" @click="deleteLabel(scope.row)" v-waves>删除</el-button>
        </template>
      </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import dayjs from 'dayjs'
import holdBillApi from '@/apis/apply/holdBill'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  data () {
    return {
      statusOptions: [
        {
          name: 'TO_BE_CONFIRMED',
          type: '待确认'
        },
        {
          name: 'TERMINATION',
          type: '终止审核'
        }
      ],
      rows: [],
      total: 0,
      listQuery: {
        condition: {
          keyword: undefined,
          startTime:undefined,
          endTime:undefined
        },
        pageInfo:{
          pageIndex:1,
          pageSize:20,
          orders: []
        }
      },
      date:[dayjs().subtract(1, 'month'),dayjs()]
    }
  },
  components:{ listConditionTemplate},
  computed: {

  },
  watch:{

  },
  mounted () {
  },
  methods: {
    showFilter (row) {
      if(row.status&&row.status.name==='TO_BE_CONFIRMED'){
        return 'primary'
      }else if(row.status&&row.status.name==='TERMINATION'){
        return 'danger'
      }else {
        return 'info'
      }
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList()
    },
    /**
     * 加载数据
     */
    getList () {
      this.listQuery.condition.startTime = dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00')
      this.listQuery.condition.endTime = dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59')
      holdBillApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleInfo(row){
      this.$router.push({path:`/business/cooperation/apply/billInfo/${row.id}`, query: { source:'holdBills'}})

    },
    handleCreate(){
      this.$router.pushTo(this.$router.routesConfig.applyNew)
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.getList(pageInfo)
    },
    deleteLabel(row){
    this.$confirm('删除后无法恢复,确定要删除该条标签申请吗？', '警告', {
        type: 'warning'
      }).then(() => {
        holdBillApi.delete(row.id).then(res => {
          if (res.data) {
            this.handleFilter()
          } else {
            this.$message.error({
              message: res.msg === '' ? '删除失败，请稍后重试！！！' : res.msg,
            })
          }
        })
      })
    }
  }
}
</script>

<style>

</style>